export const ORDER_INDEX_START_DATE_STORAGE_NAME = 'ORDER_INDEX_START_DATE';
export const ORDER_INDEX_END_DATE_STORAGE_NAME = 'ORDER_INDEX_END_DATE';

export const SAMPLE_INDEX_START_DATE_STORAGE_NAME = 'SAMPLE_INDEX_START_DATE';
export const SAMPLE_INDEX_END_DATE_STORAGE_NAME = 'SAMPLE_INDEX_END_DATE';

export const ESC_INDEX_START_DATE_STORAGE_NAME = 'ESC_INDEX_START_DATE';
export const ESC_INDEX_END_DATE_STORAGE_NAME = 'ESC_INDEX_END_DATE';

/*-------- MODEL HOUSE --------*/
export const MODEL_HOUSE_INDEX_START_DATE_STORAGE_NAME = 'MODEL_HOUSE_INDEX_START_DATE';
export const MODEL_HOUSE_INDEX_END_DATE_STORAGE_NAME = 'MODEL_HOUSE_INDEX_END_DATE';

export const MODEL_HOUSE_TASK_START_DATE_STORAGE_NAME = 'MODEL_HOUSE_TASK_START_DATE';
export const MODEL_HOUSE_TASK_END_DATE_STORAGE_NAME = 'MODEL_HOUSE_TASK_END_DATE';


/*-------- FOLLOWING PRODUCT --------*/
export const FOLLOWING_PRODUCTION_PACKING_LIST_START_DATE = 'FOLLOWING_PRODUCTION_PACKING_LIST_START_DATE';
export const FOLLOWING_PRODUCTION_PACKING_LIST_END_DATE = 'FOLLOWING_PRODUCTION_PACKING_LIST_END_DATE';

export const FOLLOWING_PRODUCTION_CALENDAR_SHOW_DATE = 'FOLLOWING_PRODUCTION_CALENDAR_SHOW_DATE';


export const FOLLOWING_PRODUCTION_TABLE_START_DATE = 'FOLLOWING_PRODUCTION_TABLE_START_DATE';
export const FOLLOWING_PRODUCTION_TABLE_END_DATE = 'FOLLOWING_PRODUCTION_TABLE_END_DATE';

export const FOLLOWING_PRODUCTION_ORDER_START_DATE = 'FOLLOWING_PRODUCTION_ORDER_START_DATE';
export const FOLLOWING_PRODUCTION_ORDER_END_DATE = 'FOLLOWING_PRODUCTION_ORDER_END_DATE';

export const FOLLOWING_PRODUCTION_TASK_ACTION_START_DATE = 'FOLLOWING_PRODUCTION_TASK_ACTION_START_DATE';
export const FOLLOWING_PRODUCTION_TASK_ACTION_END_DATE = 'FOLLOWING_PRODUCTION_TASK_ACTION_END_DATE';

export const FOLLOWING_PRODUCTION_TASK_START_DATE = 'FOLLOWING_PRODUCTION_TASK_START_DATE';
export const FOLLOWING_PRODUCTION_TASK_END_DATE = 'FOLLOWING_PRODUCTION_TASK_END_DATE';

/*-------- WAYBILL --------*/

export const WAYBILL_INDEX_START_DATE_STORAGE_NAME = 'WAYBILL_INDEX_START_DATE';
export const WAYBILL_INDEX_END_DATE_STORAGE_NAME = 'WAYBILL_INDEX_END_DATE';

export const WAYBILL_LOGO_INDEX_START_DATE_STORAGE_NAME = 'WAYBILL_LOGO_INDEX_START_DATE';
export const WAYBILL_LOGO_INDEX_END_DATE_STORAGE_NAME = 'WAYBILL_LOGO_INDEX_END_DATE';

/*-------- INVOICE --------*/

export const INVOICE_INDEX_START_DATE_STORAGE_NAME = 'INVOICE_INDEX_START_DATE';
export const INVOICE_INDEX_END_DATE_STORAGE_NAME = 'INVOICE_INDEX_END_DATE';

export const INVOICE_LOGO_INDEX_START_DATE_STORAGE_NAME = 'INVOICE_LOGO_INDEX_START_DATE';
export const INVOICE_LOGO_INDEX_END_DATE_STORAGE_NAME = 'INVOICE_LOGO_INDEX_END_DATE';

export const INVOICE_OUTGOING_INDEX_START_DATE_STORAGE_NAME = 'INVOICE_OUTGOING_INDEX_START_DATE';
export const INVOICE_OUTGOING_INDEX_END_DATE_STORAGE_NAME = 'INVOICE_OUTGOING_INDEX_END_DATE';

export const INVOICE_ERROR_INDEX_START_DATE_STORAGE_NAME = 'INVOICE_ERROR_INDEX_START_DATE';
export const INVOICE_ERROR_INDEX_END_DATE_STORAGE_NAME = 'INVOICE_ERROR_INDEX_END_DATE';

export const INVOICE_TASK_ACTION_INDEX_START_DATE_STORAGE_NAME = 'INVOICE_TASK_ACTION_INDEX_START_DATE';
export const INVOICE_TASK_ACTION_INDEX_END_DATE_STORAGE_NAME = 'INVOICE_TASK_ACTION_INDEX_END_DATE';

export const INVOICE_CUSTOM_TASK_INDEX_START_DATE_STORAGE_NAME = 'INVOICE_CUSTOM_TASK_INDEX_START_DATE';
export const INVOICE_CUSTOM_TASK_INDEX_END_DATE_STORAGE_NAME = 'INVOICE_CUSTOM_TASK_INDEX_END_DATE';

export const FINANCE_PAYMENTS_INDEX_START_DATE_STORAGE_NAME = 'FINANCE_PAYMENTS_INDEX_START_DATE';
export const FINANCE_PAYMENTS_INDEX_END_DATE_STORAGE_NAME = 'FINANCE_PAYMENTS_INDEX_END_DATE';

export const BANK_INSTRUCTIONS_INDEX_START_DATE_STORAGE_NAME = 'BANK_INSTRUCTIONS_INDEX_START_DATE';
export const BANK_INSTRUCTIONS_INDEX_END_DATE_STORAGE_NAME = 'BANK_INSTRUCTIONS_INDEX_END_DATE';

export const APPROVED_INVOICES_INDEX_START_DATE_STORAGE_NAME = 'APPROVED_INVOICES_INDEX_START_DATE';
export const APPROVED_INVOICES_INDEX_END_DATE_STORAGE_NAME = 'APPROVED_INVOICES_INDEX_END_DATE';

/*-------- FOLLOWING EXPORT --------*/

export const FOLLOWING_EXPORT_INDEX_START_DATE_STORAGE_NAME = 'FOLLOWING_EXPORT_INDEX_START_DATE';
export const FOLLOWING_EXPORT_INDEX_END_DATE_STORAGE_NAME = 'FOLLOWING_EXPORT_INDEX_END_DATE';


export const FOLLOWING_EXPORT_PACKING_LIST_INDEX_START_DATE_STORAGE_NAME = 'FOLLOWING_EXPORT_PACKING_LIST_INDEX_START_DATE';
export const FOLLOWING_EXPORT_PACKING_LIST_INDEX_END_DATE_STORAGE_NAME = 'FOLLOWING_EXPORT_PACKING_LIST_INDEX_END_DATE';

export const FOLLOWING_EXPORT_TASK_INDEX_START_DATE_STORAGE_NAME = 'FOLLOWING_EXPORT_TASK_INDEX_START_DATE';
export const FOLLOWING_EXPORT_TASK_INDEX_END_DATE_STORAGE_NAME = 'FOLLOWING_EXPORT_TASK_INDEX_END_DATE';

/*-------- REPORT --------*/
export const ENDORSEMENT_REPORT_END_DATE_STORAGE_NAME = 'ENDORSEMENT_REPORT_END_DATE_END_DATE';
export const ENDORSEMENT_REPORT_START_DATE_STORAGE_NAME = 'ENDORSEMENT_REPORT_END_DATE_START_DATE';


export const PURCHASING_REPORT_START_DATE_STORAGE_NAME = 'PURCHASING_REPORT_END_DATE_END_DATE';
export const PURCHASING_REPORT_END_DATE_STORAGE_NAME = 'PURCHASING_REPORT_END_DATE_START_DATE';

export const LOADING_DEADLINE_REPORT_START_DATE_STORAGE_NAME = 'LOADING_DEADLINE_REPORT_END_DATE_END_DATE';
export const LOADING_DEADLINE_REPORT_END_DATE_STORAGE_NAME = 'LOADING_DEADLINE_REPORT_END_DATE_START_DATE';
